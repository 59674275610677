/* eslint-disable */
import React from 'react'

import Pharmacist from '../../images/people/pharmacist.jpg'
import PharmacistMobile from '../../images/people/pharmacist-mobile.jpg'
import WomanPhone from '../../images/people/woman-on-phone.jpg'
import WomanPhoneMobile from '../../images/people/woman-on-phone-mobile.jpg'
import PharmacistShakingHands from '../../images/people/pharmacist-shaking-hands.jpg'
import PharmacistShakingHandsMobile from '../../images/people/pharmacist-shaking-hands-mobile.jpg'
import vibrantDarkBlueBackground from '../../images/backgrounds/vibrant-dark-blue.jpg'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import humana from '../../images/brand-logos/humana.svg'
import regence from '../../images/brand-logos/regence.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import united from '../../images/brand-logos/united-healthcare.svg'

import NarrowBanner from '../../components/NarrowBanner'

import {
  Billboard,
  Carousel,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title:
            'Find an in-network pharmacist near you | Clearlinkinsurance.com',
          description:
            'Find pharmacies near you that accept your coverage and may have your prescriptions in stock.',
          canonical: 'https://clearlinkinsurance.com/',
          robots: 'follow,index',
        },
        path: '/find/find-a-pharmacy',
        promoCode: '170011',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Find an in-network pharmacist near you | Clearlinkinsurance.com',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-split-content">
          <div className="wrapper">
            <Billboard
              backgroundColor="white"
              alignImageToBottom
              variant="split"
              image={
                <img
                  src={Pharmacist}
                  alt="a pharmacist leans over pharmacy counter and smiles"
                />
              }
              mobileImage={
                <img
                  src={PharmacistMobile}
                  alt="a pharmacist leans over pharmacy counter and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Medicare: Find a pharmacist in-network near you
                  </Typography>
                  <Typography variant="h5">
                    Clearlink Insurance Agency makes it easy to find a Medicare
                    pharmacy that accepts your plan’s coverage.
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to="#find-a-pharmacy-now"
                    color="pink"
                  >
                    Find a Pharmacy Now
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>

        <div className="hide-nav-button" id="find-a-pharmacy-now">
          <VariableContent
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">Find a pharmacy</Typography>
                <Typography variant="body">
                  Click or tap the button associated with your insurance carrier
                  to visit their pharmacy search page. From there, it’s easy to
                  find the selection of available pharmacists covered by your
                  plan.
                </Typography>
              </>
            }
          >
            <Stack spacing="l">
              <Columns cardStyled mobileCarousel>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={aetna}
                    alt="Aetna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.aetnamedicare.com/en/prescription-drugs/find-pharmacy.html"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={cigna}
                    alt="Cigna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.cigna.com/medicare/member-resources/pharmacy-networks"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={humana}
                    alt="Humana logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.humana.com/finder/pharmacy"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={regence}
                    alt="Regence logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://providers.regence.com/healthsparq/public/#/one/insurerCode=RG_I&brandCode=RG&productCode=&postalCode="
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={united}
                    alt="United Healthcare logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.uhc.com/medicare/health-plans/aarp-pharmacy.html#/Pharmacy-Search-English"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={wellcare}
                    alt="Wellcare logo"
                    style={{ maxWidth: '90px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://findaprovider.wellcare.com/location"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <SplitContent
          className="reverse-desktop"
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={WomanPhone}
              alt="an elderly woman sits in office chair, looks at desktop computer, and talks on mobile phone"
            />
          }
          mobileImage={
            <img
              src={WomanPhoneMobile}
              alt="an elderly woman sits in office chair, looks at desktop computer, and talks on mobile phone"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">How to search for a pharmacy</Typography>
              <Typography variant="body">
                When you click or tap the link associated with your insurance
                carrier, you’ll arrive at their “find a pharmacy” page. 
              </Typography>
              <Typography variant="body">
                From there, search by location to find a nearby, in-network
                pharmacist who accepts your Medicare plan coverage.
              </Typography>
              <Typography variant="body">
                To complete your search, you may need to input the following
                information:
              </Typography>
              <List>
                <ListItem>Medicare ID card</ListItem>
                <ListItem>Plan year and type</ListItem>
                <ListItem>Home address</ListItem>
                <ListItem>ZIP code</ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          style={{
            backgroundImage: `url(${vibrantDarkBlueBackground})`,
            color: '#fff',
          }}
          alignMainContent="Center"
          mainContent={
            <>
              <Typography variant="h1">Find specialized care</Typography>
              <Typography variant="body">
                Not looking for a pharmacist? Use the button below to return to
                our Medicare provider search hub. There, you can search for
                hospitals, dentists, primary care physicians, and other
                providers covered by your plan.
              </Typography>
              <LinkButton variant="" to="/find" color="pink">
                Find a healthcare provider
              </LinkButton>
            </>
          }
        ></VariableContent>

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={PharmacistShakingHands}
              alt="a woman smiles after a visit to the dentist's office"
            />
          }
          mobileImage={
            <img
              src={PharmacistShakingHandsMobile}
              alt="a woman smiles after a visit to the dentist's office"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Helpful tips for finding the right pharmacy
              </Typography>
              <Typography variant="body">
                Not all pharmacies are created equal—even among those who accept
                your insurance coverage.
              </Typography>
              <Typography variant="body">
                The following are a few factors to consider when picking out a
                pharmacy near you:
              </Typography>

              <List>
                <ListItem>
                  <Typography variant="h6">Location and Convenience</Typography>{' '}
                  Make sure your chosen pharmacy is not only nearby, but has
                  business hours that work with your schedule.
                </ListItem>
                <ListItem>
                  <Typography variant="h6">Pharmacy Staff</Typography> Read
                  reviews and visit a few locations to make sure the staff is
                  friendly and knowledgeable.
                </ListItem>
                <ListItem>
                  <Typography variant="h6">Additional Services</Typography>{' '}
                  Choose a pharmacy that gives you the extras you prefer, such
                  as health consultations, vaccination, or prescription
                  delivery.
                </ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          className="quote-slide-carousel"
        >
          <Carousel>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    No premium prescription insurance.
                  </Typography>
                  <Typography variant="body">
                    “I had no idea I could qualify for no premium prescription
                    insurance. Shara was really efficient and patient explaining
                    the process of obtaining the insurance.”
                  </Typography>
                  <Typography variant="body">From: Donna, US</Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">You care and show it</Typography>
                  <Typography variant="body">
                    “JoAnn was knowledgeable and patient when helping me
                    understand the different choices I had. Because I was
                    getting over the flu and sounded bad, she offered to call me
                    at another time and didn't push the conversation on me. I
                    found that very respectful and because of her concern I
                    choose to finish and enroll in a plan.”
                  </Typography>
                  <Typography variant="body">From: Jennifer R., US</Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Excellent customer service
                  </Typography>
                  <Typography variant="body">
                    “The agent who assisted me, Richard, was friendly and very
                    helpful…a great experience.”
                  </Typography>
                  <Typography variant="body">From: Verda H., US</Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
          </Carousel>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
